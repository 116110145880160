import { Fragment, useContext } from "react";
import { GlobalContext } from "../hooks/useGlobalContext";
import { DSR } from "../utils/Types";

export const RulesModal = () => {
    const { selectedRules } = useContext(GlobalContext);

    const getDsrText = (dsr?: DSR) => {
        if (dsr) {
            switch (dsr) {
                case "No DSR":
                    return "Previously played stages do not affect stage bans.";
                case "DSR":
                    return "No stage that has already been played in a set may be picked for another game in the same set, regardless of what the results of the previous game were.";
                case "Modified DSR":
                    return "A player cannot pick the last stage they have won on during the set, while any stages of previous wins are fair game.";
            }
        }
    };

    return (
        <Fragment>
            {(selectedRules?.dsr !== "" ||
                selectedRules?.counterpickBanOrder !== "" ||
                selectedRules?.starterBanOrder !== "" ||
                selectedRules?.timer != null) && (
                <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#rules"
                >
                    View rules
                </button>
            )}

            <div className="modal fade" id="rules" tabIndex={-1} aria-labelledby="rulesLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title text-start" id="rulesLabel">
                                {selectedRules?.name} rules
                            </h2>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body text-start">
                            <dl className="mb-0">
                                {selectedRules?.starterBanOrder && (
                                    <Fragment>
                                        <dt>Starter ban order</dt>
                                        <dd>{selectedRules.starterBanOrder}</dd>
                                    </Fragment>
                                )}

                                {selectedRules?.counterpickBanOrder && (
                                    <Fragment>
                                        <dt>Counterpick bans</dt>
                                        <dd>{selectedRules.counterpickBanOrder}</dd>
                                    </Fragment>
                                )}

                                {selectedRules?.timer && (
                                    <Fragment>
                                        <dt>Timer</dt>
                                        <dd>{selectedRules.timer} minutes</dd>
                                    </Fragment>
                                )}

                                {selectedRules?.dsr && (
                                    <Fragment>
                                        <dt>DSR</dt>
                                        <dd>
                                            <div>{selectedRules.dsr}</div>
                                            <div>
                                                <small className="text-muted">{getDsrText(selectedRules?.dsr)}</small>
                                            </div>
                                        </dd>
                                    </Fragment>
                                )}
                            </dl>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
