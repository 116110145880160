import { StageModel } from "../models/StageModel";
import StageBattlefield from "../assets/battlefield.jpg";
import StageFinalDestination from "../assets/final-destination.jpg";
import StageSmashville from "../assets/smashville.jpg";
import StageTownAndCity from "../assets/town-and-city.jpg";
import StageKalos from "../assets/kalos-pokemon-league.jpg";
import StageUnova from "../assets/unova-pokemon-league.jpg";
import StagePokemonStadium2 from "../assets/pokemon-stadium-2.jpg";
import StageYoshisStory from "../assets/yoshis-story.jpg";
import StageYoshisIsland from "../assets/yoshis-island.jpg";
import StageLylatCruise from "../assets/lylat-cruise.jpg";
import StagePokemonStadium from "../assets/pokemon-stadium.jpg";
import StageSmallBattlefield from "../assets/small-battlefield.jpg";
import StageHollowBastion from "../assets/hollow-bastion.jpg";
import StageNorthernCave from "../assets/northern-cave.jpg";

export class StagesService {
    getStage(id: number) {
        const stage = this.getStages().find(x => x.id === id);

        if (stage) {
            return stage;
        }

        throw new Error("Could not find stage");
    }

    getStages(): StageModel[] {
        return [
            {
                id: 1,
                name: "Final Destination",
                imageUrl: StageFinalDestination,
                selected: false,
                visible: true,
            },
            {
                id: 2,
                name: "Battlefield",
                imageUrl: StageBattlefield,
                selected: false,
                visible: true,
            },
            {
                id: 3,
                name: "Smashville",
                imageUrl: StageSmashville,
                selected: false,
                visible: true,
            },
            {
                id: 4,
                name: "Town & City",
                imageUrl: StageTownAndCity,
                selected: false,
                visible: true,
            },
            {
                id: 5,
                name: "Kalos Pokemon League",
                imageUrl: StageKalos,
                selected: false,
                visible: true,
            },
            {
                id: 6,
                name: "Unova Pokemon League",
                imageUrl: StageUnova,
                selected: false,
                visible: true,
            },
            {
                id: 7,
                name: "Pokemon Stadium 2",
                imageUrl: StagePokemonStadium2,
                selected: false,
                visible: true,
            },
            {
                id: 8,
                name: "Yoshi's Story",
                imageUrl: StageYoshisStory,
                selected: false,
                visible: true,
            },
            {
                id: 9,
                name: "Yoshi's Island",
                imageUrl: StageYoshisIsland,
                selected: false,
                visible: true,
            },
            {
                id: 10,
                name: "Lylat Cruise",
                imageUrl: StageLylatCruise,
                selected: false,
                visible: true,
            },
            {
                id: 11,
                name: "Pokemon Stadium",
                imageUrl: StagePokemonStadium,
                selected: false,
                visible: true,
            },
            {
                id: 12,
                name: "Small Battlefield",
                imageUrl: StageSmallBattlefield,
                selected: false,
                visible: true,
            },
            {
                id: 13,
                name: "Hollow Bastion",
                imageUrl: StageHollowBastion,
                selected: false,
                visible: true,
            },
            {
                id: 14,
                name: "Northern Cave",
                imageUrl: StageNorthernCave,
                selected: false,
                visible: true,
            }
        ];
    }
}
