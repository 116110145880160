import { HomePage } from "./pages/HomePage";

export const RouteList = {
    // Seeding: "/seeding",
    Home: "/",
};

export const RouteTable = [
    { path: RouteList.Home, Component: HomePage },
];
