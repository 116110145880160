import { createContext, ReactNode, useState } from "react";
import { RulesStorage } from "../models/RulesStorage";
import { RulesService } from "../services/RulesService";
import { StagesService } from "../services/StagesService";

const stagesService = new StagesService();
const rulesService = new RulesService(stagesService);

export const useGlobalContext = () => {
    const { getSenateRules } = rulesService;

    const [selectedRules, setSelectedRules] = useState<RulesStorage>();
    const [isEditing, setIsEditing] = useState(false);
    const [allRules, setAllRules] = useState(rulesService.getRules());

    const stages = stagesService.getStages();

    const addRule = (model: RulesStorage) => {
        rulesService.addRule(model);
        setSelectedRules(model);
        setIsEditing(false);
        setAllRules(rulesService.getRules());
    };

    const deleteRule = () => {
        if (selectedRules != null && window.confirm(`Are you sure you want to delete ${selectedRules.name}?`)) {
            rulesService.deleteRule(selectedRules.id);
            setSelectedRules(undefined);
            setIsEditing(false);
            setAllRules(rulesService.getRules());
        }
    };

    const editRule = (model: RulesStorage) => {
        rulesService.updateRule(model);
        setSelectedRules(model);
        setIsEditing(false);
        setAllRules(rulesService.getRules());
    };

    return {
        selectedRules,
        setSelectedRules,
        getSenateRules,
        stages,
        addRule,
        editRule,
        isEditing,
        setIsEditing,
        allRules,
        deleteRule,
    };
};

// automatic exports
export type IGlobalContext = ReturnType<typeof useGlobalContext>;
export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

type Props = {
    children: ReactNode;
};

export const GlobalContextProvider = (props: Props) => {
    const globalContext = useGlobalContext();
    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};
