import { useEffect } from "react";
import "./scss/app.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { RouteTable } from "./Routes";
import { CSSTransition } from "react-transition-group";
import { Constants } from "./utils/Constants";
import { GlobalContextProvider } from "./hooks/useGlobalContext";
import "bootstrap";

export const App = () => {
    const { animationDuration } = Constants;

    useEffect(() => {
        // redraw on resize to fix items not be vertically centered when screen sizes change
        window.addEventListener("resize", () => {
            document.body.style.display = "inline-block";

            setTimeout(() => {
                document.body.style.display = "";
            }, 1);
        });

        // set CSS animation variable here so it's referenced in on place in JS
        document.documentElement.style.setProperty("--animation-duration", `${animationDuration}ms`);
    }, [animationDuration]);

    return (
        <GlobalContextProvider>
            <Router>
                {RouteTable.map(({ path, Component }) => (
                    <Route exact path={path} key={path}>
                        {({ match }) => (
                            <CSSTransition classNames="trans" in={match != null} timeout={animationDuration} unmountOnExit>
                                <Component {...match?.params} />
                            </CSSTransition>
                        )}
                    </Route>
                ))}
            </Router>
        </GlobalContextProvider>
    );
};
