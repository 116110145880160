import * as yup from "yup";
import { DSR, DSROptions } from "../utils/Types";

export interface RulesStorage {
    name: string;
    id: string;
    starterStageIds: number[];
    counterpickStageIds: number[];
    dsr?: DSR | null;
    starterBanOrder?: string | null;
    counterpickBanOrder?: string | null;
    timer?: number | null;
}

export const RulesStorageSchema: yup.SchemaOf<RulesStorage> = yup.object().shape({
    name: yup.string().required("Please enter a ruleset name"),
    id: yup.string().required(),
    starterStageIds: yup
        .array()
        .of(yup.number().required())
        .strict()
        .required()
        .min(3, "Please select at least 3 starter stages"),
    counterpickStageIds: yup
        .array()
        .of(yup.number().required())
        .strict()
        .required()
        .min(3, "Please select at least 3 counterpick stages"),
    dsr: yup
        .mixed()
        .nullable()
        .oneOf([...DSROptions, ""], "Please select a DSR option"),
    starterBanOrder: yup
        .string()
        .nullable()
        .matches(/^[1-9](-[1-9])+$/, {
            message: "Must be in this format: 1-2-1",
            excludeEmptyString: true,
        }),
    counterpickBanOrder: yup
        .string()
        .nullable()
        .matches(/^[0-9]{1}$/, {
            message: "This must be a number",
            excludeEmptyString: true,
        }),
    timer: yup
        .number()
        .nullable()
        .transform((value, originalValue) => (originalValue?.trim() === "" ? null : value)),
});
