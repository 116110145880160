import { useContext } from "react";
import { GlobalContext } from "../hooks/useGlobalContext";
import { ComponentTransition } from "../components/animation/ComponentTransition";
import { Introduction } from "../components/Introduction";
import { RulesDisplay } from "../components/RulesDisplay";
import { RulesEditor } from "../components/RulesEditor";

export const HomePage = () => {
    const { selectedRules, isEditing } = useContext(GlobalContext);

    return (
        <div className="page">
            <ComponentTransition show={selectedRules == null && !isEditing}>
                <Introduction />
            </ComponentTransition>

            <ComponentTransition show={selectedRules != null && !isEditing}>
                {selectedRules != null && <RulesDisplay />}
            </ComponentTransition>

            <ComponentTransition show={isEditing}>
                <RulesEditor />
            </ComponentTransition>
        </div>
    );
};
