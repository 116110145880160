import { RulesStorage } from "../models/RulesStorage";
import { StagesService } from "./StagesService";
import { Constants } from "../utils/Constants";

export class RulesService {
    public jsonKey = "Rules";

    constructor(private stagesService: StagesService) {}

    getSenateRules = () => {
        const { senateRulesId } = Constants;

        const rules: RulesStorage = {
            id: senateRulesId,
            name: "Trial Australian Unified Ruleset",
            dsr: "No DSR",
            starterBanOrder: "2-3-1",
            counterpickBanOrder: "2",
            starterStageIds: [1, 2, 3, 4, 7, 10, 12],
            counterpickStageIds: [1, 2, 3, 4, 7, 10, 12],
            timer: 7,
        };

        return rules;
    };

    getRules() {
        const item = localStorage.getItem(this.jsonKey);

        if (item) {
            const storage: Array<RulesStorage> = JSON.parse(item);

            if (storage) {
                return storage;
            }
        }

        return [];
    }

    addRule(model: RulesStorage) {
        // add rules
        model.id = this.generateId();

        const rules = this.getRules();
        rules.push(model);
        this.saveRules(rules);
    }

    updateRule(model: RulesStorage) {
        const rules = this.getRules();

        for (let i = 0; i < rules.length; i++) {
            const r = rules[i];

            if (r.id === model.id) {
                rules[i] = model;
            }
        }

        this.saveRules(rules);
    }

    saveRules(rules: RulesStorage[]) {
        localStorage.setItem(this.jsonKey, JSON.stringify(rules));
    }

    getRule(id: string) {
        return this.getRules().find(x => x.id === id);
    }

    deleteRule(id: string) {
        this.saveRules(this.getRules().filter(x => x.id !== id));
    }

    generateId() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            const r = (Math.random() * 16) | 0;
            const v = c === "x" ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });
    }
}
