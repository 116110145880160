import { ErrorMessage } from "@hookform/error-message";
import { DeepMap, FieldError, Path } from "react-hook-form";

type Props<T> = {
    errors: DeepMap<T, FieldError>;
    name: Path<T>;
};

export function CustomErrorMessage<T>(props: Props<T>) {
    const { errors, name } = props;

    return (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }: { message: string }) => <div className="text-danger mb-2">{message}</div>}
        />
    );
}
