import { useEffect, useRef, useState, useContext } from "react";
import { GlobalContext } from "../hooks/useGlobalContext";
import Logo from "../assets/icons/icon-72x72.png";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

export const Introduction = () => {
    const [showInstall, setShowInstall] = useState(false);

    const deferredPrompt = useRef<BeforeInstallPromptEvent>();

    const { setSelectedRules, getSenateRules, setIsEditing, allRules } = useContext(GlobalContext);

    // this is all used to show a custom install button on Chrome only
    useEffect(() => {
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();

            if ((e as BeforeInstallPromptEvent) != null) {
                deferredPrompt.current = e as BeforeInstallPromptEvent;
            }

            setShowInstall(true);
        });

        // this fires on install PWA, even if it's done manually
        window.addEventListener("appinstalled", () => {
            // log install to analytics
            setShowInstall(false);
        });

        // this determines on load if it's operating as an app or in the browser
        window.addEventListener("DOMContentLoaded", () => {
            if ((navigator as any).standalone) {
                setShowInstall(false);
            }
            if (window.matchMedia("(display-mode: standalone)").matches) {
                setShowInstall(false);
            }
        });
    }, []);

    const install = () => {
        if (deferredPrompt.current) {
            deferredPrompt.current.prompt();

            // wait for the user to respond to the prompt
            deferredPrompt.current.userChoice.then(choiceResult => {
                if (choiceResult.outcome === "accepted") {
                    window.location.reload();
                }
            });
        }
    };

    return (
        <div className="container text-center">
            <div>
                <img alt="" src={Logo} />
            </div>

            <h1 className="mb-4">
                <div>Ausmash</div>
                <div>Stage Striker</div>
            </h1>

            <div className="mb-3">
                <button type="button" className="btn btn-primary" onClick={() => setSelectedRules(getSenateRules())}>
                    Trial Australian Unified Ruleset
                </button>
            </div>

            {allRules.length > 0 && (
                <ul className="list-unstyled">
                    {allRules.map(r => (
                        <li key={r.id} className="mb-2">
                            <button type="button" className="btn btn-secondary" onClick={() => setSelectedRules(r)}>
                                {r.name}
                            </button>
                        </li>
                    ))}
                </ul>
            )}

            <div>
                <button type="button" className="btn btn-outline-secondary" onClick={() => setIsEditing(true)}>
                    Add new ruleset
                </button>
            </div>

            {showInstall && (
                <div>
                    <button type="button" className="btn btn-primary mt-3 btn-sm" onClick={install}>
                        Install Stage Striker
                    </button>
                </div>
            )}
        </div>
    );
};
