import { useContext } from "react";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../hooks/useGlobalContext";
import { RulesStorage, RulesStorageSchema } from "../models/RulesStorage";
import { yupResolver } from "@hookform/resolvers/yup";
import { DSROptions } from "../utils/Types";
import { Textbox } from "../components/forms/Textbox";
import { RulesService } from "../services/RulesService";
import { StagesService } from "../services/StagesService";
import { StageModel } from "../models/StageModel";
import { CustomErrorMessage } from "./forms/CustomErrorMessage";
import { array } from "yup/lib/locale";

const rulesService = new RulesService(new StagesService());

export const RulesEditor = () => {
    const { selectedRules, setIsEditing, stages, deleteRule, addRule, editRule } = useContext(GlobalContext);

    const defaultValues: Partial<RulesStorage> = selectedRules ?? {
        id: rulesService.generateId(),
        starterStageIds: [],
        counterpickStageIds: [],
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<RulesStorage>({
        resolver: yupResolver(RulesStorageSchema),
        defaultValues: defaultValues,
    });

    const onSubmit = handleSubmit(model => {
        if (selectedRules) {
            editRule(model);
        } else {
            addRule(model);
        }
    });

    const updateCheckbox = (o: StageModel, field: keyof RulesStorage) => {
        const ids = watch(field);

        if (ids && Array.isArray(ids)) {
            if (ids.indexOf(o.id) > -1) {
                setValue(field, [...ids.filter(x => x !== o.id)]);

                // remove from starters if deleting a counterpick
                if (field === "counterpickStageIds") {
                    const startIds = watch("starterStageIds");
                    setValue("starterStageIds", [...startIds.filter(x => x !== o.id)]);
                }
            } else {
                setValue(field, [...ids, o.id]);

                // if adding a start we must also add a counterpick
                if (field === "starterStageIds") {
                    const counterpickIds = watch("counterpickStageIds");

                    // only add if it's not already added
                    if (counterpickIds.indexOf(o.id) === -1) {
                        setValue("counterpickStageIds", [...counterpickIds, o.id]);
                    }
                }
            }
        }
    };

    return (
        <form className="viewport" onSubmit={onSubmit}>
            <div className="container text-center py-3 border-bottom bg-light">
                <h1 className="mb-0 h2">
                    {selectedRules == null ? "Create new ruleset" : `Editing ${selectedRules.name}`}
                </h1>
            </div>

            <div className="overflow-scroll-y container py-3">
                <div className="mb-3">
                    <Textbox
                        register={register}
                        name="name"
                        errors={errors}
                        label="Ruleset name"
                        placeholder="e.g. FNS"
                    />
                </div>

                <div className="mb-3">
                    <Textbox
                        register={register}
                        name="starterBanOrder"
                        placeholder="e.g. 1-2-1"
                        errors={errors}
                        label="Starter ban order"
                    />
                </div>

                <div className="mb-3">
                    <Textbox
                        register={register}
                        errors={errors}
                        name="counterpickBanOrder"
                        placeholder="e.g. 2"
                        type="number"
                        min={1}
                        label="Counterpick bans"
                    />
                </div>

                <div className="mb-3">
                    <Textbox
                        register={register}
                        errors={errors}
                        name="timer"
                        label="Timer (minutes)"
                        placeholder="e.g. 2"
                        type="number"
                        min={1}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="dsr" className="form-label">
                        DSR
                    </label>

                    <select id="dsr" {...register("dsr")} className="form-select">
                        {DSROptions.map(o => (
                            <option key={o}>{o}</option>
                        ))}
                    </select>

                    <CustomErrorMessage errors={errors} name="dsr" />
                </div>

                <div>
                    <div className="form-label d-flex justify-content-between">
                        <div>
                            Stages
                            <small className="fw-normal ps-2">(starters / counterpicks)</small>
                        </div>
                        <div className="d-flex pe-1">
                            <div className="pe-4">S</div>
                            <div className="pe-3">C</div>
                        </div>
                    </div>

                    <CustomErrorMessage errors={errors} name="starterStageIds" />
                    <CustomErrorMessage errors={errors} name="counterpickStageIds" />

                    <ul className="list-group list-unstyled">
                        {stages.map(o => (
                            <li
                                style={{ backgroundImage: `url('${o.imageUrl}')` }}
                                key={o.id}
                                className="list-group-item d-flex justify-content-between list-group-item-stage list-group-item-action"
                            >
                                <div className="pe-3">{o.name}</div>
                                <div className="d-flex justify-content-between">
                                    <div className="pe-3 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            className="form-check-input mt-0"
                                            checked={watch("starterStageIds").indexOf(o.id) > -1}
                                            onChange={() => {
                                                updateCheckbox(o, "starterStageIds");
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            className="form-check-input mt-0"
                                            checked={watch("counterpickStageIds").indexOf(o.id) > -1}
                                            onChange={() => {
                                                updateCheckbox(o, "counterpickStageIds");
                                            }}
                                        />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="container text-center py-3 border-top bg-light">
                <div className="d-flex justify-content-between">
                    <div>
                        <button type="button" className="btn btn-secondary btn-sm" onClick={() => setIsEditing(false)}>
                            Back
                        </button>
                    </div>
                    <div>
                        {selectedRules != null && (
                            <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={deleteRule}>
                                Delete
                            </button>
                        )}

                        <button className="btn btn-primary btn-sm">Save</button>
                    </div>
                </div>
            </div>
        </form>
    );
};
