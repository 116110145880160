import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorkerRegistration.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
        if (window.confirm("New version available.  Reload?")) {
            registration.unregister().then(() => {
                window.location.reload();
            });
        }
    },
});
