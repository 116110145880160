import { Fragment } from "react";
import { DeepMap, FieldError, Path, UseFormRegister } from "react-hook-form";
import { CustomErrorMessage } from "./CustomErrorMessage";

type Props<T> = {
    register: UseFormRegister<T>;
    errors: DeepMap<T, FieldError>;
    name: Path<T>;
    label?: string;
    placeholder?: string;
    type?: string;
    min?: number;
};

export function Textbox<T>(props: Props<T>) {
    const { register, name, errors, placeholder, min, label = name, type = "text" } = props;

    return (
        <Fragment>
            <label htmlFor={name} className="form-label">
                {label}
            </label>

            <input
                id={name}
                type={type}
                min={min}
                className="form-control"
                placeholder={placeholder}
                {...register(name)}
            />

            <CustomErrorMessage errors={errors} name={name} />
        </Fragment>
    );
}
