import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../hooks/useGlobalContext";
import { StagesService } from "../services/StagesService";
import { Constants } from "../utils/Constants";
import { RulesModal } from "./RulesModal";

const stagesService = new StagesService();

export const RulesDisplay = () => {
    const { selectedRules, setSelectedRules, setIsEditing } = useContext(GlobalContext);
    const { senateRulesId } = Constants;

    const [currentStageIds, setCurrentStageIds] = useState<Array<number>>([]);
    const [selectedRound, setSelectedRound] = useState<"Starters" | "Counterpicks">("Starters");
    const [selectedStages, setSelectedStages] = useState<Array<number>>([]);

    const selectStage = (id: number) => {
        if (selectedStages.indexOf(id) > -1) {
            setSelectedStages([...selectedStages.filter(x => x !== id)]);
        } else {
            setSelectedStages([...selectedStages, id]);
        }
    };

    const reset = () => {
        setSelectedStages([]);
    };

    useEffect(() => {
        if (selectedRules) {
            if (selectedRound === "Starters") {
                setCurrentStageIds(selectedRules.starterStageIds);
            } else if (selectedRound === "Counterpicks") {
                setCurrentStageIds(selectedRules.counterpickStageIds);
            }
        }
    }, [selectedRules, selectedRound]);

    return (
        <div className="viewport">
            <div className="py-3 text-center container bg-light border-bottom">
                <h1 className="mb-3 h2">{selectedRules?.name}</h1>

                {selectedRules && (
                    <div className="d-flex justify-content-between">
                        <div className="me-3">
                            <RulesModal />
                        </div>

                        <div>
                            <div className="btn-group">
                                <button
                                    type="button"
                                    onClick={() => setSelectedRound("Starters")}
                                    className={clsx(
                                        "btn",
                                        "btn-sm",
                                        selectedRound === "Starters" ? "btn-secondary" : "btn-outline-secondary",
                                    )}
                                >
                                    Starters
                                </button>

                                <button
                                    type="button"
                                    onClick={() => setSelectedRound("Counterpicks")}
                                    className={clsx(
                                        "btn",
                                        "btn-sm",
                                        selectedRound === "Counterpicks" ? "btn-secondary" : "btn-outline-secondary",
                                    )}
                                >
                                    Counterpicks
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="overflow-scroll-y">
                <div className="container pe-0 h-100 pt-3">
                    <ul className="stages-grid">
                        {currentStageIds
                            .sort((a, b) => a - b)
                            .map(s => {
                                const stage = stagesService.getStage(s);

                                return (
                                    <li
                                        key={stage.id}
                                        className={clsx(
                                            "stages-grid-item",
                                            selectedStages.indexOf(stage.id) > -1 && "stages-grid-item--selected",
                                        )}
                                        style={{ backgroundImage: `url('${stage.imageUrl}')` }}
                                    >
                                        <button
                                            className={clsx("stages-grid-btn")}
                                            type="button"
                                            onClick={() => selectStage(stage.id)}
                                        >
                                            <span className="stages-grid-text">{stage.name}</span>
                                        </button>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>

            <div>
                <div className="text-center my-3">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={reset}
                        disabled={selectedStages.length === 0}
                    >
                        Restart striking
                    </button>
                </div>

                <div className="py-3 text-center container bg-light border-top">
                    <div className="d-flex justify-content-between">
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary btn-sm"
                                onClick={() => setSelectedRules(undefined)}
                            >
                                Home
                            </button>
                        </div>

                        <div>
                            {selectedRules?.id !== senateRulesId && (
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => setIsEditing(true)}
                                >
                                    Edit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
